import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      guest: true,
      admin: true,
      fieldRegistrar: true,
    },
  },
  {
    path: "/farmers",
    name: "farmers",
    meta: {
      guest: true,
      admin: true,
      fieldRegistrar: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Farmer/Farmers.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: {
      admin: true,
      superAdmin: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Users/Users.vue"),
  },
  {
    path: "/disabled-farmers",
    name: "disabled-farmers",
    meta: {
      guest: true,
      fieldRegistrar: true,
      admin: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Farmer/DisabledFarmers.vue"
      ),
  },
  {
    path: "/add-farmer",
    name: "add-farmer",
    meta: {
      guest: true,
      admin: true,
      fieldRegistrar: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Farmer/AddFarmer.vue"),
  },
  {
    path: "/add-community",
    name: "add-community",
    meta: {
      admin: true,
      superAdmin: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Community/AddCommunity.vue"
      ),
  },
  {
    path: "/community/:id",
    name: "edit-community",
    meta: {
      admin: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Community/AddCommunity.vue"
      ),
  },
  {
    path: "/farmer/:farmer_id",
    name: "farmer",
    meta: {
      guest: true,
      fieldRegistrar: true,
      admin: true,
    },
    component: () => import("../views/Farmer/FarmerDetails"),
  },
  {
    path: "/farmer-farms/:farmer_id",
    name: "farmer-farms",
    meta: {
      admin: true,
    },
    component: () => import("../views/Farm/Farms"),
  },
  {
    path: "/farms",
    name: "farms",
    meta: {
      admin: true,
      fieldRegistrar: true,
      guest: true,
    },
    component: () => import("../views/Farm/Farms"),
  },
  {
    path: "/communities",
    name: "communities",
    meta: {
      admin: true,
    },
    component: () => import("../views/Community/Communities"),
  },
  {
    path: "/agreement/:farmer_id",
    name: "agreement",
    meta: {
      admin: true,
    },
    component: () => import("../views/Farmer/Agreement"),
  },
  {
    path: "/factories",
    name: "factories",
    meta: {
      admin: true,
    },
    component: () => import("../views/Factory/Factories"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((userAuth) => {
    console.log(userAuth);
    if (userAuth) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(function ({ claims, ...rest }) {
          // console.log("claims:", claims, rest);

          if (claims.guest) {
            if (!to.matched.some((record) => record.meta.guest))
              return next({
                path: "/",
                query: {
                  redirect: { name: "home" },
                },
              });
          }
          if (claims.admin) {
            if (!to.matched.some((record) => record.meta.admin))
              return next({
                path: "/",
                query: {
                  redirect: { name: "home" },
                },
              });
          }
          // if (claims.admin) {
          //
          //   if (!to.matched.some(record => record.meta.superAdmin))
          //     return next({
          //       path: '/',
          //       query: {
          //         redirect: {name:"home"}
          //       }
          //     })
          // }
        });
    }
    // else {
    //   if (to.matched.some(record => record.meta.superAdmin)) {
    //     next({
    //       path: '/',
    //       query: {
    //         redirect: to.fullPath
    //       }
    //     })
    //   } else {
    //     next()
    //   }
    // }
  });

  next();
});
export default router;
