import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from './plugins/firebase.plugin'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueQriously from 'vue-qriously'

import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';
import "./styles.css";

Vue.config.productionTip = false

Vue.use(firebase);
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAVtROWacifsqk8SfyUipkhB0QU5WqNcqs',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  installComponents: true
})
Vue.use(VueQriously);


Vue.component('v-select2', vSelect)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
