<template>
  <v-container v-if="loading">
    <div id="loading">
      <v-progress-circular id="loader"indeterminate color="primary"></v-progress-circular>
    </div>

  </v-container>

    <v-app id="doc" v-else>
        <v-snackbar app bottom rounded="pill" :color="error.color" v-model="error.show" class="text-capitalize">
            <v-icon left>mdi-alert-circle</v-icon>
            {{error.message}}
        </v-snackbar>

        <div v-if="$store.getters['getAuth']">
            <v-app-bar app flat color="primary" dark class="hidden-print-only">
                <v-icon @click="$router.go(-1)" v-if="$route.name !== 'home'" left>mdi-arrow-left</v-icon>
                <v-icon v-else left>mdi-home</v-icon>
               <div data-v-3e33f716="" class="v-avatar" style="height: 62px; min-width: 62px; width: 42px;"><div data-v-3e33f716="" class="v-image v-responsive theme--light"><div class="v-responsive__sizer" style="padding-bottom: 100%;"></div><div class="v-image__image v-image__image--contain" style="background-image: url(&quot;https://koafarmer.com/img/logo.378bda3f.png&quot;); background-position: center center;"></div><div class="v-responsive__content"></div></div></div>

                <v-spacer/>
                <v-btn icon>
                    <v-icon v-if="claims" @click.native="$router.push('/users')">mdi-account-group</v-icon>
                </v-btn>
              <v-btn icon>
                    <v-icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">mdi-theme-light-dark</v-icon>
                </v-btn>
                <v-btn icon>
                    <v-icon @click="logout">mdi-logout-variant</v-icon>
                </v-btn>
            </v-app-bar>
            <v-main>
                <router-view/>
            </v-main>
        </div>
        <Auth v-else/>
    </v-app>

</template>

<script>

import Auth from '@/components/Auth.vue'

export default {
    name: 'App',

    components: {Auth},
    data: () => ({
      claims:false,
      loading: true,
        error: {
            color: 'red',
            show: false,
            message: 'Hello'
        }
    }),

    methods:{
        logout(auth){
            this.$firebase.auth().signOut()
          this.$store.commit("setAuth",false);
        }
    },

  async created() {
    this.$firebase.auth().onAuthStateChanged(user=>{
          this.$store.commit("setAuth",!!user);
          this.loading = false;
          if (!!user){
         this.$firebase.auth().currentUser.getIdTokenResult().then(({claims,})=>{
              this.claims=!!claims.admin;
            })

          }


        })
        this.$root.$on('error', (message)=>{

            this.error = {
                color: 'red',
                show: true,
                message
            }
        })
    },
}
</script>
<style scoped>
/*#loading {*/
/*  position: absolute;*/
/*  z-index: 1000;*/
/*  background-color: white;*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*  text-align: center;*/
/*  line-height: 100vh;*/
/*}*/
#loading {
  margin: auto;
  width: 60%;
  padding: 10px;
}

</style>
