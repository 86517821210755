<template>
  <v-main >
    <v-row class="fill-height bg" justify="center" align="center">
      <v-card max-width="520" min-width="350" rounded="xl">
        <form @submit.prevent="processAuth">
          <v-card-title class="primary--text text-h3 mb-3 justify-center">
            <v-img :src="require('@/assets/logo.png')" class="justify-center" style="width:150px; text-align:center" />
            <v-spacer/>
            <!-- <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-card-text class="text-center">
            <v-text-field v-model="email" filled rounded label="Email" type="email" required/>

            <v-text-field  v-model="password" filled rounded label="Password"
                           type="password" required/>
<!--            <v-select-->
<!--                v-if="!is_login"-->
<!--                v-model:="selectedRole"-->
<!--                :items="roles"-->
<!--                label="Roles"-->
<!--                solo-->
<!--            ></v-select>-->
            <v-btn :loading="loading" block color="primary" large rounded type="submit">
              <span v-if="!is_register">Login</span>
              <span v-else>Register</span>
            </v-btn>
            <p class="my-5">OR</p>
            <v-btn @click="is_register = !is_register" block color="primary" text large rounded >
              <span v-if="!is_register">Register</span>
              <span v-else>Login</span>
            </v-btn>
          </v-card-text>
        </form>
      </v-card>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: 'Auth',
  data: () => ({
    email: '',
    password: '',
    is_register: false,
    loading: false,
    selectedRole:'',
    roles: ['Admin','Guest']

  }),
  methods: {
    async processAuth() {
      this.loading = true
      if (this.is_register)
        await this.registerUser()
      else
        await this.loginUser()
      this.loading = false
    },
    async registerUser() {

        try {
          var {
            user
          } = await this.$firebase
              .auth()
              .createUserWithEmailAndPassword(this.email, this.password);
          // signout
        await  this.$firebase
              .auth()
              .signOut()
        } catch (error) {
          console.log(error.message);
        }
      },


    loginUser() {
      return this.$firebase.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .catch(this.showError)
    },
    showError(err) {
      console.error(err)
      this.$root.$emit('error', err.message)
    }
  }
}
</script>

<style scoped>
.bg {
  background: linear-gradient(90deg, #81FBB8, #28C76F);
  backgroundImage: `url(${require('@/assets/path/to/your/img.jpg')})`;
}
</style>
