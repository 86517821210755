'use strict'

import Vue from 'vue'
import axios from 'axios'
import configg from '@/config'
import firebase from 'firebase'


const baseURL = configg.serverURL

let config = { baseURL }

const _axios = axios.create(config)


_axios.interceptors.request.use(
	async function (config) {

		const {token, claims} = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);

		config.headers['authorization'] = `Bearer ${token}`;
		config.headers['x-factories'] = `${(claims.factories || []).join(',')}`;
		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	}
)

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response
	},
	function (error) {
		// Do something with response error
		return Promise.reject(error)
	}
)

Vue.use({
	install: function (Vue, options) {
		Vue.axios = _axios
		window.axios = _axios
		Object.defineProperties(Vue.prototype, {
			axios: {
				get() {
					return _axios
				}
			},
			$axios: {
				get() {
					return _axios
				}
			}
		})
	}
})

export default Plugin
